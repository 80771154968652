/** @jsx jsx */
import { jsx } from "theme-ui"
import { RiTwitterFill, RiInstagramFill } from "react-icons/ri"

import social from "../util/socialmedia.json"

const links = social.socialIcons.map((s, index) => {
  return (
    <div key={`${index}-${s.icon}`}>
      {s.icon === "twitter" ? (
        <a href={s.url}>
          <RiTwitterFill />
        </a>
      ) : null}

      {s.icon === "instagram" ? (
        <a href={s.url}>
          <RiInstagramFill />
        </a>
      ) : null}
    </div>
  )
})

const SocialIcons = () => links

export default SocialIcons
