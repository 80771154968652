/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { Link } from "gatsby"
import { RiMenuLine, RiCloseLine } from "react-icons/ri"

const MenuItems = [
  {
    path: "/about",
    title: "About",
  },
  {
    path: "/zines",
    title: "Zines",
  },
  {
    path: "/blog",
    title: "Blog",
  },
  {
    path: "/submissions",
    title: "Submissions",
  },
  {
    path: "/contact",
    title: "Contact",
  },
  {
    path: "https://www.etsy.com/uk/shop/the6ress",
    title: "Shop",
  },
]

const ListLink = ({ path, title }) => {
  const internal = /^\/(?!\/)/.test(path)
  return (
    <li>
      {internal ? (
        <Link to={path} activeClassName="active" partiallyActive={true}>
          {title}
        </Link>
      ) : (
        <a href={path}>{title}</a>
      )}
    </li>
  )
}

class Navigation extends React.Component {
  constructor(props) {
    super(props)
    this.state = { showMenu: false }
    this.handleToggleClick = this.handleToggleClick.bind(this)
  }

  handleToggleClick() {
    this.setState(state => ({
      showMenu: !state.showMenu,
    }))
  }

  render() {
    const listMenuItems = MenuItems.map((menuItem, index) => (
      <ListLink key={index} {...menuItem} />
    ))
    return (
      <nav className="site-navigation">
        <button
          onClick={this.handleToggleClick}
          className={"menu-trigger" + (this.state.showMenu ? " is-active" : "")}
        >
          <div className="icon-menu-line">
            <RiMenuLine />
          </div>
          <div className="icon-menu-close">
            <RiCloseLine />
          </div>
        </button>

        <ul>{listMenuItems}</ul>
      </nav>
    )
  }
}

export default Navigation
