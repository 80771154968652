/** @jsx jsx */
import { jsx } from "theme-ui"
import SocialIcons from "./social-icons"

const Footer = () => (
  <footer
    className="site-footer"
    sx={{
      bg: "black",
    }}
  >
    <div className="container">
      <div className="social-icons" sx={footerStyles.socialIcons}>
        <SocialIcons />
      </div>

      <p>&copy; {new Date().getFullYear()} the6ress</p>
    </div>
  </footer>
)

export default Footer

const footerStyles = {
  socialIcons: {
    a: {
      color: "socialIcons",
      ":hover": {
        color: "socialIconsHover",
      },
    },
  },
}
